import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Rólunk | A RoadRover Rentalsról
			</title>
			<meta name={"description"} content={"Üdvözöljük a RoadRover Rentalsnál, ahol történetünk összefonódik ügyfeleink számos utazásával és kalandjával."} />
			<meta property={"og:title"} content={"Rólunk | A RoadRover Rentalsról"} />
			<meta property={"og:description"} content={"Üdvözöljük a RoadRover Rentalsnál, ahol történetünk összefonódik ügyfeleink számos utazásával és kalandjával."} />
			<meta property={"og:image"} content={"https://oldis-star.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://oldis-star.com/img/859797.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://oldis-star.com/img/859797.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://oldis-star.com/img/859797.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://oldis-star.com/img/859797.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://oldis-star.com/img/859797.png"} />
			<meta name={"msapplication-TileImage"} content={"https://oldis-star.com/img/859797.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://oldis-star.com/img/3.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="380px" padding="50px 80px 80px 50px" background="--color-light" color="--dark">
				<Text
					as="h4"
					font="--base"
					color="--grey"
					letter-spacing="1px"
					text-transform="uppercase"
					margin="6px 0"
				>
					RoadRover
				</Text>
				<Text as="h2" font="--headline2" margin="0 0 12px 0">
					Rólunk
				</Text>
				<Text font="--base">
					Üdvözöljük a RoadRover Rentalsnál, ahol történetünk összefonódik ügyfeleink számos utazásával és kalandjával. Egy egyszerű elképzelésből született, hogy az autóbérlést elérhetőbbé, személyesebbé és stresszmentesebbé tegyük, és mára az utazók és a helyiek megbízható társává váltunk.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-4">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="65%"
				justify-content="center"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				lg-width="100%"
				padding="16px 16px 16px 16px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 125% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						bottom={0}
						min-height="100%"
						top={0}
						left={0}
						position="absolute"
						display="block"
						width="100%"
						right={0}
						src="https://oldis-star.com/img/4.jpg"
						object-fit="cover"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				align-items="flex-start"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				width="35%"
				flex-direction="column"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					padding="0px 0px 65% 0px"
					width="100%"
					height="auto"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						right={0}
						bottom="0px"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
						top="auto"
						min-height="100%"
						src="https://oldis-star.com/img/5.jpg"
					/>
				</Box>
				<Text
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					md-text-align="left"
				>
					A RoadRover Rentals egy maroknyi autóval és egy nagy álommal indult: újradefiniálni az autóbérlés élményét. El akartunk távolodni a személytelen tranzakcióktól, és olyan szolgáltatást akartunk létrehozni, amely inkább olyan, mintha egy barátunktól kölcsönöznénk autót. Ma szerény flottánk és elkötelezett csapatunk továbbra is fenntartja ezeket az értékeket, és biztosítja, hogy minden ügyfelünk mosolyogva távozzon.
				</Text>
				<Box
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					padding="0px 0px 65% 0px"
				>
					<Image
						display="block"
						width="100%"
						top="auto"
						bottom="0px"
						min-height="100%"
						src="https://oldis-star.com/img/6.jpg"
						object-fit="cover"
						position="absolute"
						left={0}
						right={0}
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Hisszük, hogy az autóbérlésnek ugyanolyan egyszerűnek és élvezetesnek kell lennie, mint az autóvezetésnek. Ezért összpontosítunk a következőkre:
					<br />
					- Ügyfélközpontú szolgáltatás: Meghallgatjuk az Ön igényeit, és megoldásokat kínálunk, nem csak autókat.
					<br />
					- Minőség a mennyiség helyett: A flottánk minden járművét a megbízhatóság, a kényelem és a teljesítmény alapján választjuk ki.
					<br />
					- Fenntarthatóság: Hozzájárulunk egy zöldebb holnaphoz.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Bérlésen túl
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					A RoadRover Rentalsnál többről van szó, mint autókról. Mi az élményekről és az emlékekről szólunk, amelyeket az úton szerezhet. Akár új horizontokat fedez fel, akár üzleti célból utazik, mi a lehető legélvezetesebbé szeretnénk tenni az utazását.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://oldis-star.com/img/7.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Csatlakozzon történetünkhöz
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Minden ügyfél, minden bérlés egy újabb fejezetet ad a RoadRover Rentals történetéhez. Meghívjuk Önt, hogy legyen részese folyamatos utazásunknak, ahol az Ön elégedettsége vezérli sikerünket.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://oldis-star.com/img/8.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});